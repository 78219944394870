import { render, staticRenderFns } from "./HomepageBanner.vue?vue&type=template&id=18fc43ea&scoped=true"
var script = {}
import style0 from "./HomepageBanner.vue?vue&type=style&index=0&id=18fc43ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18fc43ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/opt/atlassian/pipelines/agent/build/components/Icon.vue').default})
